import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Driver } from "../models/driver";
import { FileDetailsDTO } from "app/models/fileDTO";

export default class DriverStore {
    
    drivers: Driver[] = [];
    driversLicenseImage: string = "";
    licenseFileName: string = "";

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state
    loadingDriversLicense = false;

    constructor() {
        makeAutoObservable(this);
        this.loadDrivers();
    }

    // Computed property - returns an array of drivers sorted by surname, newest first
    get driversSorted() {
        return Array.from(this.drivers.values()).sort((a, b) => ('' + b.surname).localeCompare(a.surname));
    }

    // Load or pre-load all drivers
    listAllDrivers = async ( ) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.Drivers.list(); 
            runInAction(() => {
                this.drivers = data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    
    // loading state setter for drivers license image
    setLoadingDriversLicense = (state: boolean) => {
        runInAction(() => {
            this.loadingDriversLicense = state;
        })
    }

    // load drivers - paginated list of drivers from api
    loadDrivers = async () => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Drivers.list(); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.drivers = response.data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    downloadDriversLicense = async (id: string, tenantId: string = "") => {

        try {
            const response = await agent.Files.downloadMultiple({id: id, documentType: 'Drivers_License', tenantId: tenantId});
            runInAction(async () => {
                if(response!=undefined){
                    const file = response[0];
                    this.licenseFileName = file.FileName;
                    this.driversLicenseImage = "data:" + file.FileType + ";base64," + file.FileContent;
                }
            })
        } 
        catch (error) {
            console.log(error);
            this.setLoadingDriversLicense(false);
            throw error;
        }
    }

    
    uploadFile = async (id: string, documentType: string, file: Blob, tenantId: string | undefined) => {
        const response =  await agent.Files.uploadFile({id: id, documentType: documentType, file: file, tenantId: tenantId});
        return response;
    }

    deleteFile = async (id: string, documentType: string, documentName: string, tenantId: string = "") => {
        const response = await agent.Files.deleteNewest(id, documentType, documentName, tenantId);
        this.driversLicenseImage = "";
        this.licenseFileName = "";
        return response;
    }

    // create driver
    createDriver = async (driver: Driver) => {
        this.setLoading(true)
        try {
            const driverRequestBody = {
                FirstName: driver.firstName,
                //MiddleName: driver.middleName,
                //PreferredName: driver.preferredName,
                Surname: driver.surname,
                //DateOfBirth: driver.dateOfBirth,
                //Age: driver.age,
                //Gender: driver.gender,
                Email: driver.email,
                PhoneNumber: driver.phoneNumber,
                Address: driver.address,
                /*DriversLicenseNumber: driver.driversLicenseNumber,
                DriversLicenseState: driver.driversLicenseState,
                DriversLicenseValidFrom: driver.driversLicenseValidFrom,
                DriversLicenseExpiry: driver.driversLicenseExpiry,*/
                TenantId: driver.tenantId,
            }
            const response = await agent.Drivers.create(driverRequestBody);

            if (!response.succeeded){
                throw new Error(response.messages[0]);
            }
            this.setLoading(false);
            return response.data.id;

        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update driver
    updateDriver = async (driver: Driver) => {
        this.setLoading(true)
        try {
            const response = await agent.Drivers.update(driver);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete driver
    deleteDriver = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Drivers.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}