import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { FileDetailsDTO } from "app/models/fileDTO";

export default class FileStore {

    loading = false;
    // Todo: combine below two arrays into 1 object to avoid out-of-sync data
    files: File[] = [];
    base64: string[] = [];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    downloadMultipleFiles = async (id: string, documentType: string, tenantId: string = "") => {

        this.files = [];
        this.base64 = [];
        this.setLoading(true);

        try {
            const response = await agent.Files.downloadMultiple({id: id, documentType: documentType, tenantId: tenantId});
            runInAction(async () => {
                if(response!=undefined){
                    response.forEach(async (file: FileDetailsDTO, index: number) => {                        
                        var f = new File([file.FileContent], file.FileName, { type: file.FileType});
                        var b64 = "data:" + file.FileType + ";base64," + file.FileContent;
                        this.files.push(f);
                        this.base64.push(b64);
                    })
                }
            })            
        } 
        catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    uploadFile = async (id: string, documentType: string, file: File, tenantId: string = "") => {
        const response =  await agent.Files.uploadFile({id: id, documentType: documentType, file: file, tenantId: tenantId});
        return response;
    }

    deleteFile = async (id: string, documentType: string, documentName: string, tenantId: string = "") => {
        const response = await agent.Files.delete(id, documentType, documentName, tenantId);
        return response;
    }
}