import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Payment } from "../models/payment";
import { store, useStore } from "./store";
import { Booking } from "app/models/booking";

export default class PaymentStore {

    payments: Payment[] = [];
    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state
    paymentStoreLoaded = false;
    
    constructor() { 
        makeAutoObservable(this);
        this.listAllPayments();
    }

    // Computed property - returns an array of payments sorted by booking id descending .sort((a, b) => new Date(a.bookingId).valueOf() - new Date(b.bookingId).valueOf());
    get paymentsSorted() {
        return Array.from(this.payments.values()).sort((a, b) => ('' + b.paidDate).localeCompare('' + a.paidDate));
    }

    // Load or pre-load all payments
    listAllPayments = async ( ) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.Payments.list();
            runInAction(() => {
                this.payments = data;
                this.setCalculatedFields();
            })
            this.setLoadingInitial(false);
            this.paymentStoreLoaded = true;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            this.paymentStoreLoaded = false;
        }
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    
    // load payments - paginated list of payments from api
    loadPayments = async () => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Payments.list(); 
                
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.payments = response.data;
                this.setCalculatedFields();
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    
    setCalculatedFields = () => {
      
      const { boatStore: {boats}, driverStore: {drivers}, bookingStore: {bookings} } = useStore();

        this.payments.forEach((p, i) => {
            
            const b1 = bookings.find((b: { id: string | undefined; }) => b.id == p.bookingId); //todo: fix
            p.paymentDescription = "Some description";
        })
    }

    // create payment
    createPayment = async (payment: Payment) => {
        this.setLoading(true)
        try {
            const paymentRequestBody = {
                bookingId: payment.bookingId,
                amount: payment.amount,
                paidDate: payment.paidDate,
                tenantId: payment.tenantId,
            }
            const response = await agent.Payments.create(paymentRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update payment
    updatePayment = async (payment: Payment) => {
        this.setLoading(true)
        try {
            const response = await agent.Payments.update(payment);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
            return true;
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete payment
    deletePayment = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Payments.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}