import { Roles } from 'app/models/roles';
import React, { Suspense, Fragment, lazy, LazyExoticComponent, FC } from 'react';
import {  Route } from 'react-router-dom';
import AuthGuard from './navigation/authGuards/AuthGuard';
import GuestGuard from './navigation/authGuards/GuestGuard';
import AppLayout from './navigation/AppLayout';
import LoadingScreen from './components/LoadingScreen';
import {Helmet} from "react-helmet";

interface RouteType {
  guard?: (props: any) => JSX.Element;
  layout?: (props: any) => JSX.Element;
  component?: (props: any) => JSX.Element;
  routes?: RouteType[];
  path?: string;
  roles?: Roles[];
  title?: string;
  description?: string;
  canonicalUrl?: string;
}

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const HomePage = Loadable(lazy(() => import('./pages/website/Home')));
const NotFoundPage = Loadable(lazy(() => import('./pages/404')));
const UnauthorizedPage = Loadable(lazy(() => import('./pages/403')));
const LoginPage = Loadable(lazy(() => import('./pages/authentication/Login')));
const ForgotPasswordPage = Loadable(lazy(() => import('./pages/authentication/ForgotPassword')));
const ResetPasswordPage = Loadable(lazy(() => import('./pages/authentication/ResetPassword')));
const TenantListPage = Loadable(lazy(() => import('./pages/tenants/TenantList')));
const UserListPage = Loadable(lazy(() => import('./pages/users/UserList')));
const BoatListPage = Loadable(lazy(() => import('./pages/boat/BoatList')));
const VehicleMaintenanceListPage = Loadable(lazy(() => import('./pages/vehicleMaintenance/VehicleMaintenanceList')));
const BookingListPage = Loadable(lazy(() => import('./pages/booking/BookingList')));
const PaymentListPage = Loadable(lazy(() => import('./pages/payments/PaymentList')));
const PaymentSuccessPage = Loadable(lazy(() => import('./pages/payments/PaymentSuccess')));
const PaymentCancelPage = Loadable(lazy(() => import('./pages/payments/PaymentCancel')));
const DriverListPage = Loadable(lazy(() => import('./pages/drivers/DriverList')));
const NotificationListPage = Loadable(lazy(() => import('./pages/notifications/NotificationList')));
const InsuranceListPage = Loadable(lazy(() => import('./pages/insurances/InsuranceList')));
const SampleListPage = Loadable(lazy(() => import('./pages/samples/SampleList')));
const AccountSettingsPage = Loadable(lazy(() => import('./pages/profile/AccountSettings')));
const StopListPage = Loadable(lazy(() => import('./pages/stops/StopList')));
const TripListPage = Loadable(lazy(() => import('./pages/trips/TripList')));
const BoatTransportPage = Loadable(lazy(() => import('./pages/website/BoatTransport')));
const DarlingHarbourWaterTaxiPage = Loadable(lazy(() => import('./pages/website/DarlingHarbourWaterTaxi')));
const WaterTaxiCostPage = Loadable(lazy(() => import('./pages/website/WaterTaxiCost')));
const CheapWaterTaxiPage = Loadable(lazy(() => import('./pages/website/CheapWaterTaxi')));
const TenderPage = Loadable(lazy(() => import('./pages/website/Tender')));

const BlogLandingPage = Loadable(lazy(() => import('./pages/website/BlogLanding')));
const BlogPost1 = Loadable(lazy(() => import('./pages/website/blogs/BlogPost1')));
const BlogPost2 = Loadable(lazy(() => import('./pages/website/blogs/BlogPost2')));

export const renderRoutes = (routes: RouteType[] = []) => (
  routes.map((route, i) => {
    const Guard = route.guard || React.Component;
    const Layout = route.layout || Fragment;
    const Component = route.component || React.Component;
    const currentOrigin = window.location.origin;
    const canonicalUrl = route.canonicalUrl ?? route.path;

    return (
      <Route
        key={i}
        path={route.path}
        element={(
          <Fragment>
            {route.title &&
              <Helmet>
                <title>{route.title}</title>
                <meta name='description' content={route.description} />
                <link rel="canonical" href={currentOrigin + canonicalUrl} />
                </Helmet>
            }
            {route.guard ?
              <Guard roles={route.roles}>
                <Layout>
                  <Component />
                </Layout>
              </Guard> :
              <Layout>
                <Component />
              </Layout>
            }
          </Fragment>
        )}
      />
    ) })
);

const appName = "The Harbour Hopper"

const routes: RouteType[] = [
  {
    path: '/',
    guard: GuestGuard,
    component: HomePage,
    title: `Water Taxi in Sydney | Sydney Water Taxi Booking | Water Taxi Service`, //`${appName} | Home`
    description: 'Book a water taxi in Sydney with Sydney Cove Water Taxis. Reliable water taxi service, easy Sydney Water Taxi booking. Explore the best water taxis Sydney has to offer.',
  },
  // {
  //   path: '/home',
  //   canonicalUrl: '/',
  //   guard: GuestGuard,
  //   component: HomePage,
  //   title: `Water Taxi in Sydney | Sydney Water Taxi Booking | Water Taxi Service`, //`${appName} | Home`
  //   description: 'Book a water taxi in Sydney with Sydney Cove Water Taxis. Reliable water taxi service, easy Sydney Water Taxi booking. Explore the best water taxis Sydney has to offer.'
  // },
  // {
  //   path: '/index',
  //   canonicalUrl: '/',
  //   guard: GuestGuard,
  //   component: HomePage,
  //   title: `Water Taxi in Sydney | Sydney Water Taxi Booking | Water Taxi Service`,
  //   //`${appName} | Home`
  //   description: 'Book a water taxi in Sydney with Sydney Cove Water Taxis. Reliable water taxi service, easy Sydney Water Taxi booking. Explore the best water taxis Sydney has to offer.'
  // },
  {
    path: '/reset-password',
    guard: GuestGuard,
    component: ResetPasswordPage,
    title: `${appName} | Reset Password`
  },
  {
    path: '/boat-transport',
    guard: GuestGuard,
    component: BoatTransportPage,
    title: `Boat Transport Sydney | Boat Taxi Sydney | Boat Pick and Drop Service`,
    description: `Discover boat transport in Sydney with reliable boat taxi services. Enjoy convenient boat pick and drop service for fast, efficient travel across Sydney's beautiful waterways.`
  },
  {
    path: '/darling-harbour-water-taxi',
    guard: GuestGuard,
    component: DarlingHarbourWaterTaxiPage,
    title: `Sydney Harbour Water Taxi | Darling Harbour Water Taxi Service`,
    description: 'Experience Sydney Harbour with a water taxi! Book Sydney Harbour taxi boats and water taxis for scenic trips. Enjoy the best Sydney Harbour water taxi rides.'
  },
  {
    path: '/water-taxi-cost',
    guard: GuestGuard,
    component: WaterTaxiCostPage,
    title: `Sydney Water Taxis Cost | Water Taxi Prices Sydney | Best Price Sydney Water Taxi`,
    description: 'Find the best price for Sydney water taxis. Compare water taxi costs, rates, and prices in Sydney. Get affordable water taxi services with great Sydney water taxi prices and offers.'
  },
  {
    path: '/cheap-water-taxi',
    guard: GuestGuard,
    component: CheapWaterTaxiPage,
    title: `Cheap Water Taxi Sydney | Cheapest Water Taxi Sydney | Water Taxi Sydney Cheapest`,
    description: 'Looking for a cheap water taxi in Sydney? Discover the cheapest water taxi options for affordable travel. Get the best cheap water taxi services in Sydney!'
  },
  {
    path: '/tender',
    guard: GuestGuard,
    component: TenderPage,
    title: `Tenders Yacht | Sydney Tender Yacht | Tender Service Sydney`,
    description: 'Experience luxury on the water with our tender yacht services in Sydney. Enjoy seamless tender service for your yacht, ensuring comfort and convenience on your adventures.'
  },
  {
    path: '/blog',
    guard: GuestGuard,
    component: BlogLandingPage,
    title: `Tenders Yacht | Sydney Tender Yacht | Tender Service Sydney`,
    description: 'Experience luxury on the water with our tender yacht services in Sydney. Enjoy seamless tender service for your yacht, ensuring comfort and convenience on your adventures.'
  },
  {
    path: '/blog/water-taxi-pick-up-and-drop-off-points-around-sydney-harbour',
    guard: GuestGuard,
    component: BlogPost1,
    title: `A Guide to Water Taxi Pick-Up and Drop-Off Points Around Sydney Harbour ${appName}`,
    description: `Sydney Harbour is a world-renowned destination, offering stunning views, iconic landmarks, and endless opportunities for exploration. With water taxis providing a fast, convenient, and scenic way to travel, knowing the key pick-up and drop-off points can make your journey even smoother. Whether you're heading to a famous attraction, a hidden gem, or a waterfront event, water taxis ensure you arrive in style and on time.`
  },
  {
    path: '/blog/cheapest-water-taxi-sydney-options-for-everyone',
    guard: GuestGuard,
    component: BlogPost2,
    title: `Cheapest Water Taxi Sydney Options for Everyone | ${appName}`,
    description: `Sydney’s bustling streets are famous for their vibrancy, but they’re equally infamous for their traffic congestion. Whether you’re a tourist eager to explore the city’s iconic attractions or a local commuting to an event or meeting, navigating Sydney’s roads can often be a stressful experience. Thankfully, water taxis offer a stress-free, efficient alternative, allowing you to skip the traffic and make the most of your time in this stunning harbour city.`
  },
  {
    path: '/tender',
    guard: GuestGuard,
    component: TenderPage,
    title: `Tenders Yacht | Sydney Tender Yacht | Tender Service Sydney`,
    description: 'Experience luxury on the water with our tender yacht services in Sydney. Enjoy seamless tender service for your yacht, ensuring comfort and convenience on your adventures.'
  },

  {
    path: '/404',
    component: NotFoundPage,
    title: `${appName} | Not Found`
  },
  {
    path: '/403',
    component: UnauthorizedPage,
    title: `${appName} | Unauthorized`
  },

  {
    path: '/login',
    guard: GuestGuard,
    component: LoginPage,
    title: `${appName} | Login`
  },
  {
    path: '/forgot-password',
    guard: GuestGuard,
    component: ForgotPasswordPage,
    title: `${appName} | Forgot Password`
  },
  {
    path: '/reset-password',
    guard: GuestGuard,
    component: ResetPasswordPage,
    title: `${appName} | Reset Password`
  },
  {
    path: '/boats',
    guard: AuthGuard,
    layout: AppLayout,
    component: BoatListPage,
    title: `${appName} | Boats`
  },
  {
    path: '/vehicleMaintenance',
    guard: AuthGuard,
    layout: AppLayout,
    component: VehicleMaintenanceListPage,
    title: `Boat Maintenance` //${appName} | 
  },
  {
    path: '/stops',
    guard: AuthGuard,
    layout: AppLayout,
    component: StopListPage,
    title: `${appName} | Stops`
  },
  {
    path: '/trips',
    guard: AuthGuard,
    layout: AppLayout,
    component: TripListPage,
    title: `${appName} | Trips`
  },
  {
    path: '/drivers',
    guard: AuthGuard,
    layout: AppLayout,
    component: DriverListPage,
    title: `${appName} | Drivers`
  },
  {
    path: '/bookings',
    guard: AuthGuard,
    layout: AppLayout,
    component: BookingListPage,
    title: `${appName} | Bookings`
  },
  {
    path: '/payment-success',
    guard: GuestGuard,
    component: PaymentSuccessPage,
    title: `${appName} | Payment Success`
  },

  {
    path: '/payment-cancel',
    guard: GuestGuard,
    component: PaymentCancelPage,
    title: `${appName} | Payment Cancel`
  },
  {
    path: '/payments',
    guard: AuthGuard,
    layout: AppLayout,
    component: PaymentListPage,
    title: `${appName} | Payments`
  },
  {
    path: '/notifications',
    guard: AuthGuard,
    layout: AppLayout,
    component: NotificationListPage,
    title: `${appName} | Notifications`
  },
  {
    path: '/insurances',
    guard: AuthGuard,
    layout: AppLayout,
    component: InsuranceListPage,
    title: `${appName} | Insurances`
  },
  {
    path: '/samples',
    guard: AuthGuard,
    component: SampleListPage,
    title: `${appName} | UI Component Samples`,
    layout: AppLayout,
  },
  {
    path: '/profile',
    guard: AuthGuard,
    component: AccountSettingsPage,
    title: `${appName} | Profile`,
    layout: AppLayout,
  },
  {
    path: '/users',
    component: UserListPage,
    guard: AuthGuard,
    roles: [Roles.root, Roles.admin],
    title: `${appName} | User Administration`,
    layout: AppLayout,
  },
  {
    path: '/tenants',
    component: TenantListPage,
    guard: AuthGuard,
    roles: [Roles.root],
    title: `${appName} | Tenant Administration`,
    layout: AppLayout,
  },
  {
    path: '*',
    component: NotFoundPage
  }
];

export default routes;