import {Divider, Popover, SxProps, Theme } from "@mui/material";
import { H4 } from "./Typography";
import React, { FC } from "react";

// component props interface
interface PopoverMenuProps {
  title?: string | JSX.Element;
  popoverOpen: boolean;
  popoverClose: () => void;
  children: React.ReactNode;
  handleButton?: () => void;
  anchorRef: React.MutableRefObject<null>;
  minWidth?: number | string;
  maxWidth?: number | string;
  noHeader?: boolean;
  sx?: SxProps<Theme> | undefined;
}

const PopoverMenu: FC<PopoverMenuProps> = (props) => {

  const {
    children,
    popoverClose,
    popoverOpen,
    anchorRef,
    title,
    noHeader,
    sx,
  } = props;
  
  
  PopoverMenu.defaultProps = {
    sx: { 
      minWidth: 200, 
      maxWidth: 300,
      width: "100%",
      padding: "0.5rem 0",
    }
 };
 
  return (
    <Popover
      open={popoverOpen}
      onClose={popoverClose}
      anchorEl={anchorRef.current}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      PaperProps={{sx}}
    >
      <React.Fragment>
        {noHeader ||
          <React.Fragment>
            <H4 fontWeight="700" p={2}>
              {title || "Notifications"}
            </H4>
            <Divider />
          </React.Fragment>
        }
        {children}
      </React.Fragment>
    </Popover>
  );
};

export default PopoverMenu;
