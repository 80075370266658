import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { VehicleMaintenance } from "../models/vehicleMaintenance";

export default class VehicleMaintenanceStore {

    vehicleMaintenances: VehicleMaintenance[] = [];
    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state
    vehicleMaintenanceStoreLoaded = false;

    constructor() {
        makeAutoObservable(this);
        this.loadVehicleMaintenances();
    }

    // Computed property - returns an array of vehicle maintenances sorted by due date, critical (highest) first
    get vehicleMaintenancesSorted() {
        return Array.from(this.vehicleMaintenances.values()).sort((a, b) => new Date(b.maintenanceStatus).valueOf() - new Date(a.maintenanceStatus).valueOf());
    }

    // Load or pre-load all vehicleMaintenances
    listAllVehicleMaintenances = async ( ) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.VehicleMaintenances.list(); 
            runInAction(() => {
                this.vehicleMaintenances = data;
            })
            this.setLoadingInitial(false);
            this.vehicleMaintenanceStoreLoaded = true;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            this.vehicleMaintenanceStoreLoaded = false;
        }
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    // load vehicleMaintenances - paginated list of vehicleMaintenances from api
    loadVehicleMaintenances = async () => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.VehicleMaintenances.list(); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.vehicleMaintenances = response.data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // create vehicleMaintenance
    createVehicleMaintenance = async (vehicleMaintenance: VehicleMaintenance) => {
        this.setLoading(true)
        try {

            const vehicleMaintenanceRequestBody = {
                BoatId: vehicleMaintenance.boatId,
                MaintenanceIdentification: vehicleMaintenance.maintenanceIdentification,
                MaintenanceType: vehicleMaintenance.maintenanceType,
                PreviousResult: vehicleMaintenance.previousResult,
                BookedFor: vehicleMaintenance.bookedFor,
                BookedWith: vehicleMaintenance.bookedWith,
                RenewalAt: vehicleMaintenance.renewalAt,
                RenewalOn: vehicleMaintenance.renewalOn,
                MaintenanceStatus: vehicleMaintenance.maintenanceStatus,
                NoticePeriodDays: vehicleMaintenance.noticePeriodDays,
                NotifyOn: vehicleMaintenance.notifyOn,
                TenantId: vehicleMaintenance.tenantId,
            }
            const response = await agent.VehicleMaintenances.create(vehicleMaintenanceRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        }
        catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update vehicleMaintenance
    updateVehicleMaintenance = async (vehicleMaintenance: VehicleMaintenance) => {
        this.setLoading(true)
        try {
            const response = await agent.VehicleMaintenances.update(vehicleMaintenance);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete vehicleMaintenance
    deleteVehicleMaintenance = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.VehicleMaintenances.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}