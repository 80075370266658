import styled from "@emotion/styled";
import { Small } from "components/Typography";

const StyledSmall = styled(Small)(({ theme }) => ({
    display: "block",
    padding: "5px 1rem",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.secondary.light
          : theme.palette.divider,
    },
  }));
  export default StyledSmall;

  