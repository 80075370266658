import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Trip } from "../models/trip";
import { FileDetailsDTO } from "app/models/fileDTO";
import { store } from "./store";

export default class TripStore {
    
    trips: Trip[] = [];
    tripsLicenseImage: string = "";
    licenseFileName: string = "";

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state
    loadingTripsLicense = false;

    constructor() {
        makeAutoObservable(this);
        this.loadTrips();
    }

    // Computed property - returns an array of trips sorted by surname, newest first
    get tripsSorted() {
        return Array.from(this.trips.values())
            .sort((a, b) => (a.originStop == b.originStop)? ('' + b.destinationStop).localeCompare(a.destinationStop) : (b.originStop).localeCompare(a.originStop));
    }

    // Load or pre-load all trips
    listAllTrips = async ( ) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.Trips.list(); 
            runInAction(() => {
                this.trips = data;
                this.setCalculatedFields();
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    
    // loading state setter for trips license image
    setLoadingTripsLicense = (state: boolean) => {
        runInAction(() => {
            this.loadingTripsLicense = state;
        })
    }

    // load trips - paginated list of trips from api
    loadTrips = async () => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Trips.list(); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.trips = response.data;
                this.setCalculatedFields();
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    downloadTripsLicense = async (id: string, tenantId: string = "") => {

        try {
            const response = await agent.Files.downloadMultiple({id: id, documentType: 'Trips_License', tenantId: tenantId});
            runInAction(async () => {
                if(response!=undefined){
                    const file = response[0];
                    this.licenseFileName = file.FileName;
                    this.tripsLicenseImage = "data:" + file.FileType + ";base64," + file.FileContent;
                }
            })
        } 
        catch (error) {
            console.log(error);
            this.setLoadingTripsLicense(false);
            throw error;
        }
    }

    
    uploadFile = async (id: string, documentType: string, file: Blob, tenantId: string | undefined) => {
        const response =  await agent.Files.uploadFile({id: id, documentType: documentType, file: file, tenantId: tenantId});
        return response;
    }

    deleteFile = async (id: string, documentType: string, documentName: string, tenantId: string = "") => {
        const response = await agent.Files.deleteNewest(id, documentType, documentName, tenantId);
        this.tripsLicenseImage = "";
        this.licenseFileName = "";
        return response;
    }

    
    setCalculatedFields = () => {
      
        const { stopStore: {stops}, boatStore: {boats} } = store;
  
          this.trips.forEach((trip, i) => {
              
            const origin = stops.find(s => s.id == trip.originStop);
            const destination = stops.find(s => s.id == trip.destinationStop);

            trip.originDescription = origin?.name || "Stop name not found";
            trip.destinationDescription = destination?.name || "Stop name not found";
          })
      }

    // create trip
    createTrip = async (trip: Trip) => {
        this.setLoading(true)
        try {
            const tripRequestBody = {
                OriginStop: trip.originStop,
                DestinationStop: trip.destinationStop,
                Duration: trip.duration,
                ThreePaxCost: trip.threePaxCost,
                SevenPaxCost: trip.sevenPaxCost,
                TenantId: trip.tenantId,
            }
            const response = await agent.Trips.create(tripRequestBody);

            if (!response.succeeded){
                throw new Error(response.messages[0]);
            }
            this.setLoading(false);
            return response.data.id;

        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update trip
    updateTrip = async (trip: Trip) => {
        this.setLoading(true)
        try {
            const response = await agent.Trips.update(trip);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete trip
    deleteTrip = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Trips.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}