import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Notification } from "../models/notification";

export default class NotificationStore {
    notifications: Notification[] = [];
    smsBalance = 0.00;

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state
    notificationStoreLoaded = false;

    constructor() {
        makeAutoObservable(this);
        this.loadNotifications();
    }

    // Computed property - returns an array of notiications sorted by sendat date, newest first
    get notificationsSorted() {
        return Array.from(this.notifications.values()).sort((a, b) => new Date(b.toNumber).valueOf() - new Date(a.toNumber).valueOf());
    }

    // Load or pre-load all notifications
    listAllNotifications = async ( ) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.Notifications.list(); 
            runInAction(() => {
                this.notifications = data;
            })
            this.setLoadingInitial(false);
            this.notificationStoreLoaded = true;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            this.notificationStoreLoaded = false;
        }
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    // load notifications - paginated list of notifications from api
    getSMSBalance = async () => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Notifications.getBalance(); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.smsBalance = response.data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // load notifications - paginated list of notifications from api
    loadNotifications = async () => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Notifications.list(); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.notifications = response.data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // create notification
    createNotification = async (notification: Notification) => {
        this.setLoading(true)
        try {
            const notificationRequestBody = {
                ToNumber: notification.toNumber,
                MessageString: notification.messageString,
                TenantId: notification.tenantId,
                ScheduledAt: notification.scheduledAt,
            }
            const response = await agent.Notifications.create(notificationRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update notification
    updateNotification = async (notification: Notification) => {
        this.setLoading(true)
        try {
            const notificationRequestBody = {
                Id: notification.id,
                ToNumber: notification.toNumber,
                MessageString: notification.messageString,
                ScheduledAt: notification.scheduledAt,
            }
            const response = await agent.Notifications.update(notificationRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete notification
    deleteNotification = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Notifications.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}